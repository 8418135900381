<template>
  <div class="row">
    <div class="col-md-12 mb-2 boxFile text-center" :class="{ 'uploading': loadingUpload }">
      <TextInput
        :hide-input="iValue"
        type="file"
        :label="label"
        :accept="accept"
        @changed="(file) => choosedFile(file)"
      />
      <div v-if="iValue" class="text-center mt-2">
        <p>{{ iValue }}</p>
        <button
          class="btn btn-outline-danger btn-sm"
          :disabled="loadingRemove"
          @click="deleteFile"
        >
          <b-spinner v-if="loadingRemove" small />
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    accept: {
      type: String,
      default: "*/*",
    },
    value: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },

    businessId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loadingRemove: false,
      loadingUpload: false,
    }
  },

  computed: {
    iValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },

  mounted() {
  },

  methods: {
    choosedFile(file) {
      this.iValue = file.name
      this.$emit('upload', file);
    },

    deleteFile() {
      this.iValue = null

      this.$emit('upload', null);
    },
  },
}
</script>

<style lang="scss">
.boxFile {
  .full-width {
    width: 100% !important;
    height: auto !important;
  }
  .input-group {
    background-color: #4284f4;
    border-color: 1px solid #4284f4;
    border-radius: 0.3rem;
    color: #fff;
    max-width: 180px;
    margin: auto;
    input[type='file'] {
      cursor: pointer;
    }
    &::before {
      position: absolute;
      top: 9px;
      left: calc(50% - 45px);
      font-weight: 600;
      color: #fff;
      pointer-events: none;
      content: 'Upload File';
    }
    &:hover {
      background-color: #1e6cf2;
      border-color: #1265f1;
      &::before {
        color: #fff;
      }
    }
  }
  &.uploading {
    .input-group {
      opacity: .7;
      &::before {
        content: '';
        left: calc(50% - 0.75rem);
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: text-bottom;
        border: 0.25em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: spinner-border 0.75s linear infinite;
        animation: spinner-border 0.75s linear infinite;
      }
    }
  }
  .form-control {
    opacity: 0;
  }
}
</style>
